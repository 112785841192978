<template>
    <div class="phone-login">

        <my-form ref="myform" v-model="phoneform"  @submit="submitHandler" labelWidth="0px" class="my-form">

            <el-form-item
                prop="mobile"
                :rules="newRule('手机号', 'required', 'mobile')"
            >
                <el-input placeholder="请输入手机号" v-model="phoneform.mobile" clearable></el-input>
            </el-form-item>

            <div class="yzm">
                <el-form-item
                    prop="captcha"
                    :rules="[{ required: true, message: '验证码不能为空'}]"
                >
                    <el-input placeholder="手机验证码" v-model="phoneform.captcha" clearable></el-input>
                </el-form-item>

                <el-button
                    :disabled="disabled"
                    @click="sendcode"
                >
                    {{text}}
                </el-button>

            </div>

            <template slot="btn" slot-scope="scope">
                <el-button 
                    type="primary" 
                    style="width:100%;font-size:18px;"
                    @click="scope.submitHandler"
                >
                    {{iswx ? '绑定' : '登录'}}
                </el-button>
            </template>

        </my-form>
    </div>
</template>
<script>

import { mapGetters, mapMutations, mapActions} from 'vuex';

import LOGIN_SUCCESS from '../mixins/login-success.js';

export default {
    mixins: [LOGIN_SUCCESS],
    components: {
    },
    props: {
        openid:{
            type:String,
            default:'',
        },
        iswx:{
            type:Boolean,
            default:false
        }
    },
    data () {
        return {
            disabled: false,
            text: '发送验证码',

            phoneform: {
                mobile: '',
                captcha: '',
            },
        }
    },
    computed: {
    },
    watch: {
    },
    methods: {
        ...mapMutations({
            userinfoUpdata: 'userinfo/userinfoUpdata', //用户信息
        }),

        sendcode: function () {
            this.$refs['myform'].$refs['form'].validateField('mobile',(valid)=>{
                if(!valid){
                    //  /client/sendcaptcha.json
                    this.$post(`${this.$store.state.api.baseUrl}/notify/sendRegisterLoginCode`, {
                        mobile: this.phoneform.mobile,
                        islogin: true
                    }, (data) =>{
                        ShowMsg('验证码已发送', 'success');
                        this.settime();
                    })
                }else{
                    showMsg('请输入正确的手机号', 'error');
                }
            }); 

        },

        settime: function () {
            var that = this;
            this.text = 60;
            this.disabled = true;
            var interval = setInterval(() => {
                that.text--;
                if (that.text == 0) {
                    that.disabled = false;
                    that.text = '发送验证码'
                    clearInterval(interval);
                }
                return that.text;
            }, 1000)
        },

        submitHandler(){

            if(this.iswx){
                this.$set(this.phoneform, 'openid' , this.openid);
            }

            new Chain().link(next => {
                this.$post(`${this.$store.state.api.baseUrl}/client/mclogin.json`, this.phoneform, (data)=>{
                    this.userinfoUpdata(data);
                    showMsg('登录成功', 'success');

                    next();
                })
            }).link(next => {
                this.loginSuccess();
            }).run();
        },

    },
    created () {
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
    .my-form{
        padding-top: 10px;

        .yzm{
            display: flex;
            justify-content: space-between;

            .el-button{
                height: 40px;
                width: 120px;
                margin-left: 20px;
            }
        }
    }

    ::v-deep .el-form .el-form-item:nth-child(-n+2) {
        margin-bottom: 30px;
    }

    
    ::v-deep .el-form .el-form-item:nth-last-child(1){
        margin-top: 0px !important;
        margin-bottom: 16px !important;
    }
</style>
