var SWITCH_OBJ = {
    '0': {
        // _desc: '投标人',
        url: '/sys/ordermg/list.html',
    },
    '1': {
        // _desc: '招标人',
        url: '/sys/ordermg/list-usertype-1.html',
    },
    '2': {
        // _desc: '招标代理',
        url: '/web/zbdl.html',
    },
    '3': {
        // _desc: '合伙人，已停用',
        url: '/sys/ordermg/list-usertype-3.html',
    },
    '4': {
        // _desc: '公共资源',
        url: '/web/ggzybh.html',
    },
}

export default {
    methods: {
        loginSuccess() {
            this.$post(`${this.$store.state.api.baseUrl}/usermg/loginuser.json`, data => {
                var target = SWITCH_OBJ[data.usertype];

                if (!target) {
                    target = SWITCH_OBJ['0'];
                }

                this.goto(target.url);
            });
        }
    },
};