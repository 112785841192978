<template>
    <div>
        <div
            class="wx-login"
            v-loading="loading"
        >
            <div style="text-align: center" v-if="exist">
                <div id="wxQrcode">
                    <img :src="wxQrcodeSrc" class="qrcode-img"/>

                    <div
                        class="tips"
                        v-show="refreshShow"
                    >
                        <i class="el-icon-refresh"></i>
                        <a
                            style="padding:0;margin:0"
                            type="text"
                            @click="refreshHandler"
                        >
                            重新加载
                        </a>
                    </div>
                </div>
                <div class="remaining-text">
                    {{remainingText}}
                </div>
            </div>

            <phone-login
                v-if="!exist"
                :iswx="true"
                :openid="loginQrCodeStatusRes.openid"
            >
            </phone-login>

        </div>
    </div>
</template>
<script>

import { mapGetters, mapMutations, mapActions} from 'vuex';
import QRCode from 'qrcode'; 

import LOGIN_SUCCESS from '../mixins/login-success.js';

import phoneLogin from './phoneLogin'

export default {
    mixins: [LOGIN_SUCCESS],
    components: {
        phoneLogin
    },
    data () {
    return {
        loading:false,

        qRCodeRes:{},
        loginQrCodeStatusRes:{},

        loginQrCodeStatusInterval:undefined,
        remainingInterval:undefined,

        remainingText:'',

        refreshShow:false,
        exist:true,

        wxQrcodeSrc:''
    }
    },
    computed: {

    },
    watch: {

    },
    methods: {
        ...mapMutations({
            userinfoUpdata: 'userinfo/userinfoUpdata', //用户信息
        }),

        getQRCode:function(){
            this.loading = true;

            this.$get(`${this.$store.state.api.baseUrl}/client/loginQrCode`, {}, (data, res)=> {
                this.loading = false;
                this.qRCodeRes = data;

                let options ={
                    errorCorrectionLevel: 'H',
                    margin:0,
                    height:180,
                    width:180,
                    color: { 
                        dark: '#000',
                    },
                }

                QRCode.toDataURL(this.qRCodeRes.url, options , (err, src)=>{
                    if (!src) {
                        return;
                    }
                    this.wxQrcodeSrc = src;
                });

                this.getLoginQrCodeStatus();
                this.countdown(this.qRCodeRes.expire);
            })
        },

        getLoginQrCodeStatus:function(){

            this.loginQrCodeStatusInterval = setInterval(()=>{

                this.$get(`${this.$store.state.api.baseUrl}/client/loginQrCodeStatus`, {
                    sceneStr: this.qRCodeRes.sceneStr,
                }, (data)=> {

                    if(!!data && data.hasOwnProperty('exist')){

                        this.loginQrCodeStatusRes = data;

                        if(!!this.remainingInterval){
                            clearInterval(this.remainingInterval);
                        }

                        let qrcodeimgEl = document.querySelector('.qrcode-img');
                        qrcodeimgEl.style.opacity = '0.1'
                        
    
                        this.remainingText = '二维码已扫描';
                        this.refreshShow = true;

                        if(data.exist === true){

                            clearInterval(this.loginQrCodeStatusInterval);
                            this.openidLogin(data.openid);

                        }else if(data.exist === false){

                            clearInterval(this.loginQrCodeStatusInterval);
                            this.exist = false;
    
                        }
                    }
                })
            }, 1500)
        },

        openidLogin:function(openid){
            new Chain().link(next => {
                this.$post(`${this.$store.state.api.baseUrl}/client/openidLogin`, {
                    openid,
                }, (data, res)=> {
                    this.userinfoUpdata(data);
                    showMsg('登录成功', 'success');

                    next();
                })
            }).link(next => {
                this.loginSuccess();
            }).run();
        },

        countdown:function(seconds) {
            this.remainingInterval = setInterval(()=>{
                seconds--;
                if (seconds === 0) {

                    clearInterval(this.remainingInterval);
                    clearInterval(this.loginQrCodeStatusInterval);
                    
                    let qrcodeimgEl = document.querySelector('.qrcode-img');
                    qrcodeimgEl.style.opacity = '0.1'

                    this.remainingText = '二维码已失效';
                    this.refreshShow = true;
                } else {
                    let remainingMinutes = Math.floor(seconds / 60);
                    let remainingSeconds = seconds % 60 < 10 ? '0' + seconds % 60 : seconds % 60;
                    this.remainingText = remainingMinutes + ' 分 ' + remainingSeconds + ' 秒后失效';
                }
            }, 1000);
        },

        refreshHandler:function(){
            this.refreshShow = false;

            this.wxQrcodeSrc = '';
            this.remainingText = '';

            let qrcodeimgEl = document.querySelector('.qrcode-img');
            qrcodeimgEl.style.opacity = '1';

            this.getQRCode();
        },

    },
    created () {

    },
    mounted () {
        this.getQRCode();
    },

    beforeDestroy () {
        clearInterval(this.remainingInterval);
        clearInterval(this.loginQrCodeStatusInterval);
    }
}
</script>
<style lang="scss" scoped>
    @import '@css/var.scss';
    .wx-login{
        .wxQrcode{
            font-size: 0;
            position: relative;

            img{
                transition: all 0.3s; 
            }
        }

        .tips{

            white-space:nowrap;
            position:absolute;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%);
            font-size:16px;
            z-index:99;
            color:$primaryColor; 
            font-weight: bold !important;
            cursor: pointer;
        }

        .remaining-text{
            height: 28px;
            line-height: 28px;
            opacity: 0.6;
        }
    }

</style>
