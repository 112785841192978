<template>
    <div>
        <my-form ref="myform" v-model="form"  @submit="submitHandler" labelWidth="0px" class="my-form">

            <el-form-item prop="username" :rules="[{ required: true, message: '用户名不能为空'}]">
                <el-input
                    ref="username"
                    placeholder="请输入用户名/手机号"
                    v-model="form.username"
                    clearable
                >
                </el-input>
            </el-form-item>

            <el-form-item prop="pwd" :rules="[{ required: true, message: '密码不能为空'}]">
                <el-input
                    ref="pwd"
                    type="password"
                    placeholder="请输入密码"
                    v-model="form.pwd"
                    clearable
                >
                </el-input>
            </el-form-item>

            <template slot="btn" slot-scope="scope">
                <el-button 
                    type="primary" 
                    style="width:100%;font-size:18px;"
                    @click="scope.submitHandler"
                >
                    登录
                </el-button>
            </template>

        </my-form>

    </div>
</template>
<script>

import { mapGetters, mapMutations, mapActions} from 'vuex';

import LOGIN_SUCCESS from '../mixins/login-success.js';
import md5 from '@js/md5.js'

export default {
    mixins: [LOGIN_SUCCESS],
    components: {
    },
    data () {
        return {
            form:{
                username:'',
                pwd:'',
            }
        }
    },
    computed: {


    },
    watch: {
    },
    methods: {
        ...mapMutations({
            userinfoUpdata: 'userinfo/userinfoUpdata', //用户信息
        }),

        submitHandler(){
            new Chain().link(next => {
                let sendForm = {
                    username: this.form.username,
                    pwd: md5(this.form.pwd),
                };

                this.$post(`${this.$store.state.api.baseUrl}/client/uplogin.json`,sendForm,(data)=>{
                    this.userinfoUpdata(data);
                    showMsg('登录成功', 'success');

                    next();
                })
            }).link(next => {
                this.loginSuccess();
            }).run();
        },
    },
    created () {
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
    .my-form{
        padding-top: 10px;
    }
    .el-form .el-form-item:nth-child(-n+2) {
        margin-bottom: 30px;
    }

    ::v-deep .el-form .el-form-item:nth-last-child(1){
        margin-bottom: 16px !important;
    }
</style>

